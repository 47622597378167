
@font-face {
  font-family: "Desyrel";
  src: url('../fonts/desyrel.ttf') format('truetype');
}

@font-face {
  font-family: "Futura";
  src: url('../fonts/futura-medium-bt.ttf') format('truetype');
}
@font-face {
  font-family: "Futura-Bold";
  src: url('../fonts/futura-bold-font.ttf') format('truetype');
}
@font-face {
  font-family: "Futura-Italic";
  src: url('../fonts/futura-italic-font.ttf') format('truetype');
}

@font-face {
  font-family: "GT-America";
  src:  url('../fonts/gt-america-regular.ttf') format('truetype'), 
        url('../fonts/gt-america-regular.otf') format('opentype');
}
@font-face {
  font-family: "GT-America-Bold";
  src:  url('../fonts/gt-america-bold.ttf') format('truetype'),
        url('../fonts/gt-america-bold.otf') format('opentype');
}
@font-face {
  font-family: "GT-America-Bold-Italic";
  src:  url('../fonts/gt-america-bold-italic.ttf') format('truetype'),
        url('../fonts/gt-america-bold-italic.otf') format('opentype');
}

.font {
  &-desyrel {
    font-family: "Desyrel";
  }
  &-futura {
    font-family: "Futura";
  }
  &-gt-america {
    font-family: "GT-America";
  }
}

//------------------------ Bold ----------------------------
h1,h2,h3,h4,h5,strong {
  .font {
    &-futura {
      font-family: "Futura-Bold";
    }
    &-gt-america {
      font-family: "GT-America-Bold";
    }
  }
}
h1.font,h2.font,h3.font,h4.font,h5.font,strong.font {
  &-futura {
    font-family: "Futura-Bold";
  }
  &-gt-america {
    font-family: "GT-America-Bold";
  }
}

//------------------------ Italics ----------------------------
em {
  .font {
    &-futura {
      font-family: "Futura-Italic";
    }
    &-gt-america {
      font-family: "GT-America-Bold-Italic";
    }
  }
}
em.font {
  &-futura {
    font-family: "Futura-Italic";
  }
  &-gt-america {
    font-family: "GT-America-Bold-Italic";
  }
}
.font {
  &-futura {
    em {
      font-family: "Futura-Italic";
    }
  }
  &-gt-america { 
    em {
      font-family: "GT-America-Bold-Italic";
    }
  }
}