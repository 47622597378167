.card-listing {
    margin-left: auto;
    margin-right: auto;

    max-width: $container-lg-width;

    .card {
        transition: all 1s;
        margin: 0.75rem;
        display: block;
        position: relative;
        box-sizing: border-box;
        flex: 0 0 calc(33% - 1.5rem);
        padding: 5rem;
        transition: all 0.5;
        color: var(--text);
        img{
            max-width: 100%;
        }

        & * {
            margin-bottom: 15px;
        }

        h3 {
            font-size: 2.4rem;
            line-height: 2.9rem;
        }

        &-list {
            width:100%;
            display: flex;
            flex-wrap: wrap;
        }

        @media (max-width: $breakpoint-smtablet-max) {
            a[href^="mailto:"]{
                line-break: anywhere;
            }
            flex: 0 0 100%;
            margin: 0 0 3rem 0;
            &:last-child {
                margin-bottom: 0;
            }
            &-list {
                flex-direction: column;
            }
        }
    }

    a.card {
        padding: 5rem 5rem 10rem 5rem;

        // &::after {
        //     @include icon;
        //     @include iArrow;
        //     font-size: 2.5rem;
        //     position: absolute;
        //     right: 5rem;
        //     bottom: 5rem;
        //     transition: all 0.5s;
        // }

        &:hover {
            //color: var(--text) !important;
            //background-color: $white !important;
            box-shadow: 0px 0px 50px rgba(34, 32, 89, 0.1);

            .dark-back & {
                box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.1);
            }

            &::after {
                //color: var(--light);
                transform: translateX(0.5rem);
            }
        }

        @media (max-width: $container-lg-width) {
            padding: 3rem 3rem 8rem 3rem;
        }
    }

    &-promise {
        align-items: flex-start;

        &__center {
            align-items: center;
        }

        .card {
            flex: 1 0 calc(20% - 1.5rem);

            &-list {
                justify-content: space-between;
            }

            img {
                max-width: 60%;
            }

            @media (max-width: $breakpoint-tablet-max) {
                flex: 0 0 100%;
                margin-bottom: 3rem;
                &:last-child {
                    margin-bottom: 0;
                }
                &-list {
                    flex-direction: column;
                }
            }
        }
    }

    &-bg .card {
      background: $white;

      .dark-back & {
          border: none;
          filter: brightness(0.95);
          background: var(--dark);
          color: var(--dark-back-text);
      }

      .light-back & {
          border: none;
          filter: brightness(0.95);
          background: var(--light);
          color: var(--light-back-text);
      }

      .grey-back & {
          border: none;
          filter: brightness(0.95);
          background: var(--grey);
          color: var(--grey-back-text);
      }
    }
}
