li i {
  margin-right: 10px;
}

.list-style-none ul, .rich-text.list-style-none ul {
  padding-left: 0;

  li::before {
    display: none !important;
  }
}

ul.bullets,.rich-text ul.bullets {
  &-green {
    li::before {
      color: green;
    }
  }
  &-red {
    li::before {
      color: red;
    }
  }
}