.content-hero {
  &-content {
      margin: 0 auto 6rem auto;
      padding: 0 $sm-pixels;
      max-width: 1440px;
      text-align: center;

      p {
          margin-bottom: 3rem;
      }
  }
  &-image {
      height: 380px;
  }

  @media (max-width: $breakpoint-mobile-max) {
      &-content {
          margin: 0 auto 3rem auto;
          padding: 0 $xs-pixels;
      }
      &-image {
          height: 250px;
      }
  }
}

.hero {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    // height: calc(90vh - 100px);
    // max-height: 800px;
    // min-height: 600px;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;

    &-content-wrapper,&-image {
      grid-row-start: 1; 
      grid-column-start: 1; 
      position: relative; 
      height: auto;
    }

    &-content-wrapper {
      display: flex;
      flex-basis: 1440px;
      justify-content: center;
      width: 100%;
      max-width: 1440px;
      position: relative;
      z-index: 200;

      &-left,&-left-top,&-left-bottom {
        justify-content: start;
        text-align: left;
      }
      &-right,&-right-top,&-right-bottom {
        justify-content: end;
        width: 100%;
        text-align: right;
      }
      &-center-top,&-left-top,&-right-top {
        align-items: flex-start;
      }
      &-center-bottom,&-left-bottom,&-right-bottom {
        align-items: flex-end;
      }
      &-center,&-center-top,&-center-bottom {
        justify-content: center;
        text-align: center;
        hr {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    &-content {
        width: 50%;
        p{

            font-size:2.2rem;
            line-height: 3rem;

            &:first-of-type {
                margin-top:3rem;
            }
        }
    }

    &__left {
        /*this is default*/
    }

    &__left-top {
        .hero {
            &-content {
                margin-top: 6rem;
            }
        }
        align-items: flex-start;
    }

    &__right-top {
        align-items: flex-start;
        .hero {
            &-content {
                margin-top: 6rem;
            }
            &-content-wrapper {
                justify-content: flex-end;
            }
        }
    }
    &__center-top {
        align-items: flex-start;
        .hero {
            &-content {
                margin-top: 6rem;
                width: 100%;
                text-align: center;
                hr {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    &__left-bottom {
        align-items: flex-end;
        .hero {
            &-content {
                margin-bottom: 6rem;
            }
        }
    }

    &__right-bottom {
        align-items: flex-end;
        .hero {
            &-content-wrapper {
                justify-content: flex-end;
            }
            &-content {
                margin-bottom: 6rem;
            }
        }
    }
    &__center-bottom {
        align-items: flex-end;
        .hero {
            &-content {
                width: 100%;
                text-align: center;
                hr {
                    margin-left: auto;
                    margin-right: auto;
                }
                margin-bottom: 6rem;
            }
        }
    }

    @media (max-width: $breakpoint-mobile-max) {
        padding: 0; //6rem 3rem;
        min-height: unset;
        height: unset;
        display: flex;
        flex-direction: column-reverse;
        align-items: unset;

        .hero-content {
            width: 100%;
            margin: unset;
            background-color: $white;
            padding: 3rem;
            @include boxShadow;
            color: var(--text);
            &-wrapper{
                flex-basis: unset;
            }
        }

        .hero-image{
            position: relative;
        }

        // &.hero-mobile{
        //     &__top{
        //         align-items: flex-start;
        //     }
        //     &__middle{
        //         align-items: center;
        //     }
        //     &__bottom{
        //         align-items: flex-end;
        //     }
        // }
    }

    &-image {
        width: 100%;
        z-index: 100;

        &.overlay {
            &::after {
                content: "";
                display: block;
                position: absolute;
                background-color: $black;
                opacity: 0.5;
                width: 100%;
                height: 100%;
                z-index: 150;
                top: 0;
                left: 0;
            }
        }
    }
}
