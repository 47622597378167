.grid {
  display: grid;

  &.grid-cols-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &.gap-50px {
    gap: 50px;
  }
  &.gap-80px {
    gap: 80px;
  }

  @media (min-width: $breakpoint-tablet-min){
    &.tablet {
      &-grid-cols-2 {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  @media (min-width: $breakpoint-desktop-min){
    &.desktop {
      &-grid-cols-3 {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}