.icon-listing{
    margin-left: auto;
    margin-right: auto;
    max-width: $container-lg-width;


    .icon-card{
        box-sizing: border-box;
        flex: 0 0 25%;
        padding-right:3rem;
        margin-top:3rem;

        img{
          margin-bottom:4rem;
          height: 100px;
        }
        h3{
          font-size: 3.2rem;
          line-height: 4.3rem;
          @media (max-width: $breakpoint-tablet-max) {
            font-size: 2.4rem;
            line-height: 4.1rem;
          }
        }
        &-list{
          display:flex;
          flex-wrap: wrap;
        }
        &-media{
          height: 110px;
        }
        &-head{
          height: 170px;
        }

        @media (min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-tablet-max){
            flex: 0 0 50%;
        }

        @media (max-width: $breakpoint-mobile-max){
            flex: 0 0 100%;
            padding-right:0;
            &-list{
                flex-wrap: wrap;
            }
        }
    }
}