.color {
  &-text {
    color: var(--text);
  }
  &-light {
    color: var(--light);
  }
  &-dark {
    color: var(--dark);
  }
  &-tertiary {
    color: var(--tertiary);
  }
  &-grey {
    color: var(--grey);
  }
}