.min {
  &-child {
    &-width {
      &-400 {
        > div {
          min-width: 400px;
        }
      }
    }
  }
}

.max {
  &-child {
    &-width {
      &-400 {
        > div {
          max-width: 400px;
        }
      }
    }
  }
}