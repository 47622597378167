.flex {
  display: flex;

  &-grow{
      flex-grow: 1;
  }
  &-shrink{
      flex-shrink:1;
  }

  &.flex-row {
    flex-direction: row;
  }
  &.flex-col {
    flex-direction: column;
  }

  &.justify {
    &-start {
      justify-content: flex-start;
    }
    &-center {
     justify-content: center;
    }
    &-end {
      justify-content: flex-end;
    }
    &-between {
      justify-content: space-between;
    }
  }

  &.align {
    &-start {
      align-items: flex-start;
    }
    &-center {
      align-items: center;
    }
    &-end {
      align-items: flex-end;
    }
  }

  &-wrap {
    flex-wrap: wrap;
  }

  &-cols {
    &-3 {
      > div {
        width: 30%;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-lgtablet-min){
  .lgtablet-flex {
    &-cols-2 {
      > div {
        width: 45%;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-lgdesktop-min){
  .lgdesktop-flex {
    &-cols-3 {
      > div {
        width: 30%;
      }
    }
  }
}