@font-face {
  font-family: 'custom-icons';
  src:  url('../fonts/custom-icons.eot?280hzi');
  src:  url('../fonts/custom-icons.eot?280hzi#iefix') format('embedded-opentype'),
    url('../fonts/custom-icons.ttf?280hzi') format('truetype'),
    url('../fonts/custom-icons.woff?280hzi') format('woff'),
    url('../fonts/custom-icons.svg?280hzi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.custom-icon {
  font-family: 'custom-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-arrow:before {
  content: "\e900";
}
.i-plus:before {
  content: "\e901";
}
.i-minus:before {
  content: "\e902";
}
.i-tick:before {
  content: "\e903";
}
.i-cross:before {
  content: "\e904";
}
.i-facebook:before {
  content: "\e905";
}
.i-linkedin:before {
  content: "\e906";
}
.i-youtube:before {
  content: "\e907";
}
.i-twitter:before {
  content: "\e908";
}
.i-email:before {
  content: "\e909";
}
.i-link:before {
  content: "\e90a";
}
.i-phone:before {
  content: "\e90b";
}
.i-search:before {
  content: "\e90c";
}
.i-arrow-plain:before {
  content: "\e90d";
}
.i-dropdown:before {
  content: "\e90e";
}
.i-dropdown-1:before {
  content: "\e90f";
}
.i-play-button .path1:before {
  content: "\e910";
  color: var(--light);
}
.i-play-button .path2:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.15; 
}
.i-play-button .path3:before {
  content: "\e912";
  margin-left: -1em;
  color: var(--light);
}
.icon-play-button .path4:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  position: relative;
  z-index: 1;
}
