.p {
  &-sm {
    padding: $sm-pixels !important;
  }
  &-md {
    padding: $md-pixels !important;
  }
  &-lg {
    padding: $lg-pixels !important;
  }

  &x {
    &-sm {
      padding-left: $sm-pixels !important;
      padding-right: $sm-pixels !important;
    }
    &-md {
      padding-left: $md-pixels !important;
      padding-right: $md-pixels !important;
    }
    &-lg {
      padding-left: $lg-pixels !important;
      padding-right: $lg-pixels !important;
    }
  }
  
  &y {
    &-sm {
      padding-top: $sm-pixels !important;
      padding-bottom: $sm-pixels !important;
    }
    &-md {
      padding-top: $md-pixels !important;
      padding-bottom: $md-pixels !important;
    }
    &-lg {
      padding-top: $lg-pixels !important;
      padding-bottom: $lg-pixels !important;
    }
  }
}