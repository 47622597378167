$white:         #fff;
$black:         #000;
$grey:          #F4F4F4;
$grey-back:     #F5F5F5;
$grey-border:   #C3CED6;

$form-colour:   #838486;
$form-border:   #DDDEE0;
$disabled:      #DDDEE0;
$error-border:  #FF0000;
$error-back:    #FFEBEB;

$solid-black:				#000000;
$studio-grey:				#CCD6D6;
$skip-blue:         #0000ff;
$edge-blue:         #040456;
$flexi-red:         #331226;
$beyond-teal:       #003d42;
$active-blue:       #7deae7;
$active-red:        #ff4217;
$active-turquoise:  #0dd1bf;