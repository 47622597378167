.color {
  &-white {
    color: $white;
  }

  &-solid-black {
    color: $solid-black;
  }
  &-studio-grey {
    color: $studio-grey;
  }
  &-skip-blue {
    color: $skip-blue;
  }
  &-edge-blue {
    color: $edge-blue;
  }
  &-flexi-red {
    color: $flexi-red;
  }
  &-beyond-teal {
    color: $beyond-teal;
  }
  &-active-blue {
    color: $active-blue;
  }
  &-active-red {
    color: $active-red;
  }
  &-active-turquoise {
    color: $active-turquoise;
  }
}