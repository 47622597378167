//mixins -----------------------------------------------------
@mixin icon {
  font-family: 'custom-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin iArrow {
  content: "\e900";
}
@mixin iCross {
  content: "\e904";
}
@mixin iPlus {
  content: "\e901";
}

@mixin iMinus {
  content: "\e902";
}