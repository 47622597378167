.video-content {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-areas: "content video";
    &-wrapper{
        max-width: $container-lg-width;
        margin-left: auto;
        margin-right:auto;
    }

    &-content {
        padding-right: 1.5rem;
        grid-area: content;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .title{
            h2{
            font-size:3rem;
            line-height: 3rem;
            }
        }

        p {
            margin-bottom: $xs-pixels;
        }

        >div {
            width: 100%;
            padding: $xs-pixels;
            padding-right: 0;
        }
    }

    &-video {
        grid-area: video;


        .video button{
            width:100%;
            max-width: unset;
        }

        img {
            width: 100%;
        }
    }
    @media (max-width: $breakpoint-tablet-max) and (min-width: $breakpoint-tablet-min) {
        grid-template-columns: 1fr 1fr;
        p{font-size:1.3rem; line-height: 1.7rem;}
    }
    @media (max-width: $breakpoint-mobile-max) {
        display: block;

        &-content {
            padding-right: 0;
            padding-left: 0;
            width:100%;

            

            >div {
                max-width: unset;

                @media (max-width: $breakpoint-mobile-max) {
                    padding-left: $sm-pixels;
                    padding-right: $sm-pixels;
                }

                @media (max-width: $breakpoint-xsmobile-max) {
                    padding-left: $xs-pixels;
                    padding-right: $xs-pixels;
                }
            }
        }

        &-video {
            margin-right: 0;
            margin-left: 0;
        }
    }
}